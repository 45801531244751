<template>
    <div class="engine-index-page" v-if="engineData && engineData.engine">
        <!-- <div class="engine-page-title">我的翻译引擎</div> -->
        <el-card class="engine-page-card" style="margin-top:0;">
            <div class="flex flex-justify-between">
                <el-descriptions :title="engineData.engine.engineName">
                    <el-descriptions-item label="语言方向">
                        {{engineData.model.source === 'zh'?'中文':'英文'}} 
                        <i class="el-icon-arrow-right"></i> 
                        {{engineData.model.target === 'zh'?'中文':'英文'}} 
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="领域">文化/影视</el-descriptions-item> -->
                </el-descriptions>
                <div class="icon-content">
                    <div class="icon-back">
                        <svg-icon name="ie-robot" style="width: 64px;height: 64px;"></svg-icon>
                    </div>
                </div>
            </div>
        </el-card>
        <div class="engine-page-status progresses" v-show="!modelCanUse">
            <div class="card-title-content">
                <div class="left">
                    <div class="title"><i class="el-icon-loading"></i> 引擎启动中</div>
                    <div class="subtitle">粘贴翻译暂不能使用，“从测试集选一段”可正常使用</div>
                </div>
                <div class="right">
                    <i class="el-icon-time"></i>
                </div>
            </div>
        </div>
        <el-card class="engine-page-card">
            <div class="card-title-content" style="border:none;">
                <div class="left">
                    <div class="title">质量评估</div>
                </div>
                <div class="right">
                    <!-- <el-button round plain size="mini">从测试集选一段</el-button> -->
                    <el-button round plain size="mini" icon="el-icon-download" :loading="downloadTestSetBtnLoading" @click="downloadTestSetBtnEvent">下载测试集</el-button>
                </div>
            </div>
            <div class="assess-textarea">
                <el-input v-model="form.source" :disabled="!modelCanUse" type="textarea" :rows="6" resize="none" :maxlength="600" @input="inputChangeEvent" placeholder="粘贴一段该领域相关文本（不要用上传过的训练语料），测试一下效果，最多600字"></el-input>
                <div class="clear-btn" @click="form.source = ''" v-if="form.source.length>0">
                    <i class="el-icon-close"></i>
                </div>
                <el-tooltip :content="form.source.length>0?'换一段':'从测试集选一段'" placement="top">
                    <div class="change-assess-value" @click="randomTestSet">
                        <svg-icon name="ib-refresh" v-if="form.source.length>0"></svg-icon>
                        <svg-icon name="ib-extract" v-else></svg-icon>
                    </div>
                </el-tooltip>
            </div>
            <div class="compare-content">
                <div class="system-engine">
                    <div class="basic-info">
                        <div class="left">
                            <div class="title">
                                <div class="engine-icon">
                                    <svg-icon name="ie-lang-x" style="width: 14px;height: 14px;"></svg-icon>
                                </div>
                                LanguageX
                            </div>
                            <div class="field"><span>训练语料数量</span><span>千万级句对</span></div>
                        </div>
                        <div class="right">
                            <div class="field" style="margin-bottom:6px;">
                                <span>Bleu值评分 
                                    <el-tooltip content="Bleu值即机翻质量自动评分，仅供参考" placement="top">
                                        <svg-icon name="ib-help-o"></svg-icon>
                                    </el-tooltip>
                                </span>
                                <span>{{engineData.langX && engineData.langX.bleu || 0}}</span>
                            </div>
                            <div class="field"><span>更新时间</span><span>{{engineData.langX && engineData.langX.date || ''}}</span></div>
                        </div>
                    </div>
                    <div class="result-content" v-html="form.langXTrans"></div>
                </div>
                <div class="custom-engine">
                    <div class="basic-info">
                        <div class="left">
                            <div class="title">
                                <div class="engine-icon">
                                    <svg-icon name="ie-robot" style="width: 14px;height: 14px;"></svg-icon>
                                </div>
                                {{engineData.engine.engineName}}
                            </div>
                            <div class="field"><span>训练语料数量</span><span>{{engineData.model.trainSentCount || 0}}条</span></div>
                        </div>
                        <div class="right">
                            <div class="field" style="margin-bottom:6px;">
                                <span>Bleu值评分 
                                    <el-tooltip content="Bleu值即机翻质量自动评分，仅供参考" placement="top">
                                        <svg-icon name="ib-help-o"></svg-icon>
                                    </el-tooltip> 
                                </span>
                                <span>{{engineData.model.bleu || 0}}</span>
                            </div>
                            <div class="field"><span>更新时间</span><span>{{engineData.model.date}}</span></div>
                        </div>
                    </div>
                    <div class="result-content" v-if="resultSwitchValue" v-html="form.newModelTrans"></div>
                    <div class="result-content" v-else>
                        <template v-for="(diff,index) in form.newModelTransDiff">
                            <span :key="index" v-if="diff[0] === 0">{{diff[1]}}</span>
                            <span class="delete" :key="index" v-else-if="diff[0] === -1">{{diff[1]}}</span>
                            <span class="insert" :key="index" v-else-if="diff[0] === 1">{{diff[1]}}</span>
                        </template>
                    </div>
                    
                    <div class="result-switch">
                        <el-tooltip :content="resultSwitchValue?'显示差异':'显示译文'" placement="top">
                            <el-switch v-model="resultSwitchValue"></el-switch>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </el-card>
        <div class="action-content">
            <el-dropdown @command="commandHandle">
                <el-button plain round style="margin-right:10px;">更多 <i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="rename">重命名引擎</el-dropdown-item>
                    <el-dropdown-item command="delete" divided style="color:red;">删除引擎</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button plain round @click="setEngineStatusBtnEvent(false)" v-if="engineData.status === 'used'">从机翻阵列移除</el-button>
            <el-button type="primary" round @click="setEngineStatusBtnEvent(true)" v-else>加入机翻阵列</el-button>
        </div>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteEngineEvent"></alert-dialog>
        <el-dialog title="重命名引擎" center :visible.sync="renameDialog.show" destroy-on-close width="480px" top="20px" @close="renameForm.name=''">
            <el-form label-width="100px" style="margin-right:40px;">
                <el-form-item label="引擎名称" required>
                    <el-input placeholder="输入引擎名称，不超过10个字符" v-model="renameForm.name" :maxlength="10"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button round plain size="small" @click="renameDialog.show = false">取消</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" :loading="renameDialog.loading" @click="saveRenameBtnEvent">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import DiffMatchPatch from 'diff-match-patch';
import fileDownload from 'js-file-download';
import { myEngineApi } from '@/utils/api';
import AlertDialog from '@/components/AlertDialog';

const dmp = new DiffMatchPatch(); //diff差异
dmp.Diff_EditCost = 5;
export default {
    components:{ AlertDialog },
    data(){
        return {
            queryModelStatusTimer: null,
            modelCanUse: true,
            alertInfo:{
                message:'确定要删除已训练的引擎，并从机翻阵列移除吗？删除不可恢复，可重新提交语料训练',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            engineData: null,
            testSetList: [],
            downloadTestSetBtnLoading: false,
            renameDialog:{
                show:false,
                loading:false,
            },
            renameForm:{
                name:'',
            },
            form:{
                source: '',
                langXTrans: '',
                newModelTrans: '',
            },
            queryTranslateTimer: null,

            setEngineStatusBtnLoading: false,

            resultSwitchValue: false,
        }
    },
    methods:{
        initPage(){
            let url = myEngineApi.queryData;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.engineData = res.data;
                    if(res.data.stage !== 'completed'){
                        this.$router.replace('/engine/create');
                    }else{
                        this.getTestSet();
                        this.pollingModelStatus();
                        this.queryModelStatusTimer = setInterval(()=>{
                            this.pollingModelStatus();
                        },2000)
                    }
                }
            })
        },
        pollingModelStatus(){
            let url = `${myEngineApi.queryModelStatus}?modelId=${this.engineData.modelId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.modelCanUse = res.data;
                    if(res.data){
                        this.$message.success('引擎启动成功');
                        clearInterval(this.queryModelStatusTimer);
                    }
                }
            });
        },
        //获取测试集
        getTestSet(){
            let url = this.$ajax.format(myEngineApi.getTestSet,{id: this.engineData.modelId});
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.testSetList = res.data;
                }
            });
        },
        randomTestSet(){
            let len = this.testSetList.length;
            if(len > 0){
                let index = Math.floor(Math.random() * len);
                let item = this.testSetList[index];
                let d = dmp.diff_main(item.langXTrans,item.newModelTrans);
                dmp.diff_cleanupSemantic(d);
                this.form = {
                    ...this.form,
                    source: item.source,
                    langXTrans: '<p>' + item.langXTrans.replace(/\n/g,'</p><p>') + '</p>',
                    newModelTrans: '<p>' + item.newModelTrans.replace(/\n/g,'</p><p>') + '</p>',
                    newModelTransDiff: d,
                }
            }
        },
        commandHandle(command){
            switch (command){
                case 'rename':
                    this.renameDialog.show = true;
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    break;
            }
        },
        deleteEngineEvent(){
            let self = this;
            let url = this.$ajax.format(myEngineApi.deleteEngine,{id: this.engineData.engineId});
            this.$ajax.delete(url).then(res=>{
                if(res.status === 200){
                    this.$message.success('引擎已删除');
                    setTimeout(()=>{
                        self.$router.replace('/engine/create');
                    },1000);
                }
            })
        },
        saveRenameBtnEvent(){
            if(!this.renameForm.name){
                this.$message.error('请输入引擎名称');
                return;
            }
            this.renameDialog.loading = true;
            let url = myEngineApi.updateEngineName;
            this.$ajax.put(url,{
                engineId: this.engineData.engineId,
                engineName: this.renameForm.name
            }).then(res=>{
                if(res.status === 200){
                    this.renameDialog.show = false;
                    this.$message.success('重命名成功');
                    this.engineData.engine.engineName = this.renameForm.name;
                }
            }).finally(()=>{
                this.renameDialog.loading = false;
            })
        },
        downloadTestSetBtnEvent(){
            let downMsg = this.$message({
                message: '下载中...',
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.downloadTestSetBtnLoading = true;
            let url = this.$ajax.format(myEngineApi.downloadTestSet,{id: this.engineData.modelId});
            this.$ajax.axios.get(url,{responseType:'blob'}).then(res=>{
                let timestamp=new Date().getTime();  
                fileDownload(res, `${this.engineData.engine.engineName}-测试集-${timestamp}.xlsx`);
            }).finally(()=>{
                this.downloadTestSetBtnLoading = false;
                downMsg.close();
            })
        },

        /**设置引擎状态 */
        setEngineStatusBtnEvent(status){
            this.setEngineStatusBtnLoading = true;
            let url = myEngineApi.setEngineStatus;
            this.$ajax.post(url,{
                engineId: this.engineData.engineId,
                modelId: this.engineData.modelId,
                canTrans: status
            }).then(res=>{
                if(res.status === 200){
                    if(status){
                        this.$message.success('已加入机翻阵列');
                        this.engineData.status = 'used'
                    }else{
                        this.$message.success('已从机翻阵列中移除');
                        this.engineData.status = 'non-used'
                    }
                }
            }).finally(()=>{
                this.setEngineStatusBtnLoading = false;
            })
        },

        /**输入框内容发生变化时获取翻译结果 */
        inputChangeEvent(val){
            let self = this;
            clearTimeout(this.queryTranslateTimer);
            if(!this.form.source) return;
            this.queryTranslateTimer = setTimeout(()=>{
                self.queryTranslateResult();
            },500);
        },
        queryTranslateResult(){
            let url = myEngineApi.queryTranslationResult;
            this.$ajax.post(url, {
                modelId: this.engineData.modelId,
                sourceText: this.form.source,
                sourceLang: this.engineData.model.source,
                targetLang: this.engineData.model.target,
            }).then(res=>{
                if(res.status === 200){
                    this.form = {
                        ...this.form,
                        ...res.data,
                        langXTrans: '<p>' + res.data.langXTrans.replace(/\n/g,'</p><p>') + '</p>',
                        newModelTrans: '<p>' + res.data.newModelTrans.replace(/\n/g,'</p><p>') + '</p>',
                        newModelTransDiff: dmp.diff_main(res.data.langXTrans, res.data.newModelTrans)
                    }
                }
            })
        }
    },
    beforeRouteLeave: function(to, from , next){
        if(this.form.source){
            // next(false);
            this.$confirm('确定要离开吗？离开会清空本页内容', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                next();
            })
        }else{
            next();
        }
    },
    mounted(){
        this.initPage();
    },
    destroyed(){
        clearInterval(this.queryModelStatusTimer);
    }
}
</script>
<style lang="scss" scoped>
.engine-index-page{
    padding: 20px 20px 80px 20px;
    width: 860px;
    margin: auto;
    > .engine-page-title{
        font-size: 20px;
        text-align: center;
    }
    .steps-content{
        margin-top: 20px;
        .el-steps--simple{
            background: initial;
        }
    }
    .engine-page-message{
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;
        padding: 10px 20px;
        background-color: #f7efe0;
        border-radius: 4px;
    }
    .icon-content{
        .icon-back{
            width: 64px;
            height: 64px;
            background-color:#FFCC7E;
            border-radius: 50%;
            font-size: 32px;
            display: flex;
            justify-content: center;
            align-items: center; 
        }
    }
    .assess-textarea{
        border: 1px solid #000;
        border-radius: 8px;
        position: relative;
        padding: 2px;
        ::v-deep .el-textarea__inner{
            height: 100%;
            background-color: inherit;
            border-radius: 8px;
            border: none;
            padding: 18px;
            color: #000;
            font-size: 15px;
        }
        .clear-btn{
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;
            cursor: pointer;
        }
        .change-assess-value{
            width: 32px;
            height: 32px;
            position: absolute;
            right: 15px;
            bottom: 10px;
            color: #fff;
            background-color: #00000080;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .compare-content{
        display: flex;
        margin-top: 20px;
        > div{
            flex: 1;
            border-radius: 10px;
            min-height: 390px;
            padding: 20px;
            position: relative;
            &:first-child{
                margin-right: 10px;
            }
            &:last-child{
                margin-left: 10px;
            }
            .engine-icon{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                font-size: 14px;
            }
            .basic-info{
                display: flex;
                justify-content: space-between;
                .title{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 5px;
                }
                .field{
                    font-size: 14px;
                    line-height: 24px;
                    span{
                        &:first-child{
                            font-weight: 500;
                        }
                        &:last-child{
                            margin-left: 10px;
                        }
                    }
                }
            }
            .result-content{
                margin-top: 20px;
                font-size: 15px;
                line-height: 21px;
                .delete{
                    color:#FE8E0B;
                    text-decoration: line-through;
                }
                .insert{
                    color:#00B670;
                }
            }
            .result-switch{
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
            &.system-engine{
                background-color: #EBF8F3;
                .engine-icon{
                    background-color: #BCEDD9;
                }
            }
            &.custom-engine{
                background-color: #FFF7EB;
                .engine-icon{
                    background-color: #FFCC7E;
                }
            }
        }
    }
    > .action-content{
        position: fixed;
        text-align: center;
        padding:10px;
        background-color:#f8f8f8;
        bottom: 0;
        width: 840px;
    }
}
.card-title-content{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #0000001a;
    box-sizing: border-box;
    .title{
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
        margin-bottom: 5px;
    }
    .subtitle{
        font-size: 15px;
        color: #999999;
        line-height: 24px;
    }
}
.engine-page-card{
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    &.is-success{
        background-color: #D7F2E8;
        .card-title-content{
            .title{
                color: #00B049;
            }
            .right{
                i{
                    color: #00B049;
                    &.el-icon-circle-check{
                        display: block;
                    }
                    &.el-icon-circle-close{
                        display: none;
                    }
                }
            }
        }
        .effective-size{
            color: #00B049;
        }
    }
    &.is-error{
        background-color: #FEEFEF;
        .card-title-content{
            .title{
                color: #FF0000;
            }
            .right{
                i{
                    color: #FF0000;
                    &.el-icon-circle-check{
                        display: none;
                    }
                    &.el-icon-circle-close{
                        display: block;
                    }
                }
            }
        }
        .effective-size{
            color: #FF0000;
        }
    }
}
.engine-page-status{
    padding: 25px 30px;
    margin-top: 20px;
    border-radius: 10px;
    .card-title-content{
        border: none;
        padding-bottom: 0;
        .title{
            font-weight: 500;
        }
        .subtitle{
            color: #000000;
            font-size: 14px;
        }
        .right{
            i{
                font-size: 43px;
            }
        }
    }
    &.progresses{
        background-color: #f7efe0;
        .right{
            i{
                color: #EB9E07;
            }
        }
    }
    &.error{
        background-color: #FEEFEF;
        .card-title-content{
            border: none;
            .title{
                color: #F86161;
            }
            .right{
                i{
                    color: #F86161;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.engine-page-card{
    .el-card__body{
        padding: 30px;
    }
    .el-form-item__label{
        line-height: 20px;
        font-size: 15px;
        color: #000000;
    }
    .el-descriptions__title{
        font-size:18px;
        font-weight: 500;
    }
    .el-descriptions__body{
        background-color: inherit;
        color: #212B36;
        font-size: 15px;
    }
    .el-descriptions-item__label{
        color: #000000;
    }
}
</style>
